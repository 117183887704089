import { ThemeProvider } from 'styled-components';
import { saasModernTheme } from 'common/src/theme/saasModern';
import React, { Fragment } from 'react';
import SEO from '../../components/seo';
import { ResetCSS } from 'common/src/assets/css/style';
import { ContentWrapper, GlobalStyle } from '../../containers/SaasModern/sassModern.style';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import Navbar from '../../containers/SaasModern/Navbar';
import Footer from '../../containers/SaasModern/Footer';
import DummyBanner from '../../containers/SaasModern/Banner/DummyBanner';
import AffiliateProcess from '../../containers/SaasModern/WorkingProcess/AffiliateProcess';
import FaqSection from '../../containers/SaasModern/Faq';

export default () => {
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO title="B2B SaaS Leads Affiliate Program"/>

        <ResetCSS/>
        <GlobalStyle/>

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar/>
            </DrawerProvider>
          </Sticky>
          <DummyBanner titleText="B2B SaaS Leads Affiliate Program"
                       subtitleText={`Join the B2B SaaS Leads affiliate program and start selling B2B leads in 60 seconds!`}/>

          <AffiliateProcess/>
          <FaqSection faqData={faqData} hideExtraMessage/>
          <Footer/>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

const faqData= [
  {
    title: 'How much do I get paid?',
    description: 'You earn a 30% lifetime commission from each sale you make. For subscriptions, this could translate into thousands of dollars a year of recurring income!'
  },
  {
    title: 'How do I get paid?',
    description: 'You can receive monthly payouts via PayPal, Bank/Wire Transfer or Payoneer'
  },
  {
    title: 'How much does it cost?',
    description: 'It’s completely free to join the B2BSaaSLeads Affiliate Program. There’s no charges to apply, no minimum sales requirement and no minimum payouts!'
  },
  {
    title: 'Who can become an affiliate partner?',
    description: 'Absolutely anyone! Our current affiliates come from diverse backgrounds and roles - bloggers, youtubers, influencers, micro-SaaS founders, tech writers, marketing agencies, consultants and more!'
  },
  {
    title: 'How/Where can I share the affiliate link?',
    description: "You can promote B2BSaaSLeads offerings across any distribution channels you own. That could be your website, newsletter, social accounts or even your personal network.\nThe more links and content you share, the more qualified visitors you’re likely to send us which translates into higher earnings for you."
  },
  {
    title: 'There\'s something I need help with, who can I ask?',
    description: 'Just drop us an email at hello@b2bsaasleads.com, we have a dedicated team just for you. We can help you with signing up, targeting customers, building a content strategy and anything else you might need to be a successful B2BSaaSLeads affiliate.'
  },
  {
    title: 'What\'s your cookie window?',
    description: '90 days cookie time! That means, if you send us a visitor and they purchase from us within 90 days, you still get paid!'
  },
  {
    title: 'How do I sign up?',
    description: "Reach out to us at hello@b2bsaasleads.com and we'll quickly get you signed up and ready to go!"
  }
];

import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import ProcessItem from './workingProcess.style';
import Button from 'common/src/components/Button';

const AffiliateProcess = ({
                            sectionWrapper,
                            secTitleWrapper,
                            secText,
                            secHeading,
                            processRow,
                            processCol,
                            processImageStyle,
                            processTitleStyle,
                            processDescriptionStyle
                          }) => {

  return (
    <Box {...sectionWrapper} as="section" id="feature_section">
      <Container>
        <Box {...secTitleWrapper} style={{ marginBottom: '70px' }}>
          <div style={{ height: '5rem' }}/>
          <Text {...secText} content="HOW IT WORKS"/>
          <Heading
            {...secHeading}
            content="Sign up, start sharing and earn from every sale"
          />
        </Box>

        <Box {...processRow}>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="Contact us 🎯" {...processTitleStyle} />
              <Text
                content="Reach out to us at hello@b2bsaasleads.com and we'll get you onboarded to our affiliate platform."
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="Start sharing 💎" {...processTitleStyle} />
              <Text
                content="Start blogging, podcasting, tweeting or however you'd like to get the word out and start sharing B2B SaaS Leads."
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
          <Box
            {...processCol}
            className="process_item_col"
          >
            <ProcessItem className="process_item">
              <Heading as="h3" content="Start earning 💰" {...processTitleStyle} />
              <Text
                content="For every sale, you get a flat 30%. For subscriptions, you get 30% each year! Join now and start earning!"
                {...processDescriptionStyle}
              />
            </ProcessItem>
          </Box>
        </Box>

        <Box style={{ marginBottom: '70px' }}>
          <a href="mailto:hello@b2bsaasleads.com">
            <Button title={'Contact us'}/>
          </a>
        </Box>
      </Container>
    </Box>
  );
};

AffiliateProcess.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  processRow: PropTypes.object,
  processCol: PropTypes.object,
  processImageStyle: PropTypes.object,
  processTitleStyle: PropTypes.object,
  processDescriptionStyle: PropTypes.object
};

AffiliateProcess.defaultProps = {
  sectionWrapper: {
    pt: ['60px', '15px', '15px', '15px', '15px'],
    pb: 0
  },
  secTitleWrapper: {
    mb: ['60px', '100px']
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#2aa275',
    mb: '5px'
  },
  secHeading: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '500',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
    lineHeight: '1.67'
  },
  processRow: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['0', '-15px', '-20px', '-35px', '-45px'],
    mr: ['0', '-15px', '-20px', '-35px', '-45px']
  },
  processCol: {
    width: [1, 1 / 3],
    pl: ['0', '15px', '20px', '35px', '45px'],
    pr: ['0', '15px', '20px', '35px', '45px'],
    mb: '40px'
  },
  processImageStyle: {
    mb: '35px',
    width: ['60px', '60px', '70px', 'auto']
  },
  processTitleStyle: {
    fontSize: ['20px', '18px', '20px', '20px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.02em',
    mb: ['20px', '20px', '22px', '22px', '22px']
  },
  processDescriptionStyle: {
    fontSize: ['15px', '15px', '15px', '15px'],
    fontWeight: '400',
    color: '#343d48',
    lineHeight: '1.87'
  }
};

export default AffiliateProcess;
